import { useEffect } from "react";
import { useLang } from "../../../../../../language";
import { Navigate, Route, Routes } from "react-router-dom";
import { LayoutDescConstNavTop } from "./LayoutDescConstNavTop";
import { Metricas } from "../Metricas/Metricas";
import { ElementosConstructivosServices } from "../../controllers/elemconst.services";
import { LoaderFormPrimary } from "../../../../layout/LoaderFormPrimary";
import { MantenimientoPreventivoIndex } from "../MantenimientoPreventivo";
import { MantenimientoCorrectivoIndex } from "../MantenimientoCorrectivo";
import { isFetchDataInitLoader } from "../../../../../../helpers/helpers";

export const LayoutDescConst = ({ id, text, isObraNueva, idedificio, setDefaultLink, defaultLink }: any) => {

    const Lang = useLang()

    let navLinks = [
        {
            title: Lang('SISTEMAS'),
            link: 'sistemas'
        },
        {
            title: Lang('MANTENIMIENTO_PREVENTIVO'),
            link: 'mantenimiento-preventivo'
        },
    ]

    if (!isObraNueva) {
        
        navLinks.push(
            {
                title: Lang('MANTENIMIENTO_CORRECTIVO'),
                link: 'mantenimiento-correctivo'
            },
        )
    }

    const [fetchMisElemsConst, fetchMisElemsConstData] = ElementosConstructivosServices.GET_BY_EDIFICIO()

    useEffect(() => {


        if (id === null || id !== undefined) {

            fetchMisElemsConst({
                id: idedificio + '/edificio',
                params: {
                    padre_id: id,
                    arbol: true,
                    // mostrar_todos: false,
                }
            })
        }

    }, [id]);    

    return (
        <>
            {
                isFetchDataInitLoader(fetchMisElemsConstData) ?
                    <div className="mt-3">
                        <LoaderFormPrimary />
                    </div>
                    :
                    <>
                        <div className="LayoutDescConst__NavTop d-flex">

                            <LayoutDescConstNavTop
                                navLinks={navLinks}
                                text={text}
                                id={id}
                                idedificio={idedificio}
                                elemento_completado={fetchMisElemsConstData?.data?.[0]?.elemento_completado}
                                defaultLink={defaultLink}
                                setDefaultLink={setDefaultLink}
                            />

                        </div>

                        <div className="LayoutDescConst__Container mt-3">

                            <Routes>

                                <Route
                                    path='sistemas/*'
                                    element={
                                        <Metricas
                                            fetchMisElemsConstData={fetchMisElemsConstData}
                                            id={id}
                                            idedificio={idedificio}
                                        />
                                    }
                                />

                                <Route
                                    path='mantenimiento-preventivo/*'
                                    element={
                                        <MantenimientoPreventivoIndex
                                            id={id}
                                            idedificio={idedificio}
                                        />
                                    }
                                />

                                <Route
                                    path='mantenimiento-correctivo/*'
                                    element={
                                        <MantenimientoCorrectivoIndex
                                            id={id}
                                            idedificio={idedificio}
                                        />
                                    }
                                />
                                <Route
                                    path="*"
                                    element={<Navigate to='sistemas' replace />}
                                />

                            </Routes>
                        </div>

                    </>
            }
        </>
    );
};
