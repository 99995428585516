import React, { useState } from "react";
import ModalAdvanced from "../../../../../../components/ModalAdvanced/ModalAdvanced";
import { Typography } from "@mui/material";
import { useLang } from "../../../../../../language";
import { ListOptions } from "../../../../../../components/ListOptions/ListOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

export const ModalEscogerNuevaActividad = ({ data, handleClose, handleShowModal_NuevaActividad, idCurrentFase }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const listOptions = [
        {
            id: 'option1',
            title: Lang('CREAR_ACTIVIDAD'),
            action: () => {
                handleClose()
                handleShowModal_NuevaActividad({
                    action: 'CREATE',
                    idCurrentFase,
                })
            },
            icon: <FontAwesomeIcon icon={faPlusSquare} className="fa-2x" />
        },
        {
            id: 'option2',
            title: Lang('IMPORTAR_ACTIVIDAD'),
            action: () => navigate('../importar-actividad'),
            icon: <FontAwesomeIcon icon={faFileImport} className="fa-2x" />
        },
    ]


    return (
        <ModalAdvanced data={data} handleClose={handleClose} size='md'>

            <ModalAdvanced.Head>

                <Typography variant="h6" component="h2">
                    {Lang('NUEVA_ACTIVIDAD')}
                </Typography>

            </ModalAdvanced.Head>

            <ModalAdvanced.Body>

                <Typography variant="body1" component="h2" className="text-center">
                    {Lang('SELECCIONE_OPCION_CREAR_ACTIVIDAD')}
                </Typography>

                <div className="mt-4">
                    <ListOptions
                        list={listOptions}
                    />
                </div>

            </ModalAdvanced.Body>

            <ModalAdvanced.Footer>

            </ModalAdvanced.Footer>

        </ModalAdvanced>

    )
};
