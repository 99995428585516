import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useLang } from "../../../../../../../language";
import { DocumentacionServices } from "../controllers/documentacion.services";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { isFetchDataInitLoader } from "../../../../../../../helpers/helpers";
import { isEmpty } from "lodash";
import { Placeholder } from "../../../../../../../components/Placeholder/Placeholder";
ChartJS.register(ArcElement, Tooltip, Legend);

export const ResumenCostes = ({ idedificio }: any) => {

    const Lang = useLang()

    const [fetchResumenMinGET, fetchResumenMinGETData] = DocumentacionServices.GET_RESUMEN_COSTES(idedificio)

    const [fetchResumenMaxGET, fetchResumenMaxGETData] = DocumentacionServices.GET_RESUMEN_COSTES(idedificio)

    useEffect(() => {

        fetchResumenMinGET({
            params: {
                condicion: 'coste_minimo'
            }
        })

        fetchResumenMaxGET({
            params: {
                condicion: 'coste_maximo'
            }
        })

    }, []);

    const calcularPorcentaje = (listaCostes: Array<any>) => {

        let total = 0
        let totalCostes: any = []
        var textos = [];

        for (let i = 0; i < listaCostes.length; i++) {
            total += (listaCostes[i].total_coste || 0)
        }

        for (let i = 0; i < listaCostes.length; i++) {

            let porcentaje = ((listaCostes[i].total_coste || 0) / total) * 100;

            totalCostes.push(porcentaje)

            textos.push(listaCostes[i].texto + ' (' + porcentaje.toFixed(2) + '%)')
        }

        return [totalCostes, textos]
    }

    return (
        <div>

            <Grid container columnSpacing={3} rowSpacing={1} display={'flex'} justifyContent='center'>

                <Grid item xs={12} lg={5} xl={4}>
                    <h3 className="text-center">{Lang('COSTE_MINIMO')}</h3>
                    {
                        isFetchDataInitLoader(fetchResumenMinGETData) ?
                            <Placeholder
                                height='300px'
                                width='300px'
                                borderRadius='300px'
                            />
                            :
                            <>
                                {
                                    isEmpty(fetchResumenMinGETData?.data) ?
                                        <p className="text-center">
                                            {Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}
                                        </p>
                                        :
                                        <Doughnut data={
                                            {
                                                labels: calcularPorcentaje(fetchResumenMinGETData.data)[1],
                                                datasets: [
                                                    {
                                                        label: Lang('COSTE_MINIMO'),
                                                        data: calcularPorcentaje(fetchResumenMinGETData.data)[0],
                                                        backgroundColor: [
                                                            'rgb(255, 99, 132)',
                                                            'rgb(0,212,180)',
                                                            'rgb(142,68,173)',
                                                            'rgb(54, 162, 235)',
                                                            'rgb(231,76,60)',
                                                            'rgb(170,183,184)',
                                                            'rgb(255, 205, 86)',
                                                        ],
                                                    }
                                                ]
                                            }
                                        } />
                                }
                            </>
                    }
                </Grid>

                <Grid item xs={12} lg={5} xl={4}>
                    <h3 className="text-center">{Lang('COSTE_MAXIMO')}</h3>
                    {
                        isFetchDataInitLoader(fetchResumenMaxGETData) ?
                            <Placeholder
                                height='300px'
                                width='300px'
                                borderRadius='300px'
                                className="delay-1"
                            />
                            :
                            <>
                                {
                                    isEmpty(fetchResumenMaxGETData?.data) ?
                                        <p className="text-center">
                                            {Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}
                                        </p>
                                        :
                                        <Doughnut data={
                                            {
                                                labels: calcularPorcentaje(fetchResumenMaxGETData.data)[1],
                                                datasets: [
                                                    {
                                                        label: Lang('COSTE_MAXIMO'),
                                                        data: calcularPorcentaje(fetchResumenMaxGETData.data)[0],
                                                        backgroundColor: [
                                                            'rgb(255, 99, 132)',
                                                            'rgb(0,212,180)',
                                                            'rgb(142,68,173)',
                                                            'rgb(54, 162, 235)',
                                                            'rgb(231,76,60)',
                                                            'rgb(255, 205, 86)',
                                                            'rgb(170,183,184)',
                                                        ],
                                                    }
                                                ]
                                            }
                                        } />
                                }
                            </>
                    }

                </Grid>
            </Grid>
        </div>
    )
};
