import { Box, Grid } from '@mui/material'
import { InputText } from '../../../../../../../../components/Inputs'
import { useLang } from '../../../../../../../../language'
import { InputChecks } from '../../../../../../../../components/Inputs/InputChecks'

export const FormSimple = ({ formValuesSearch, handleInputChangeSearch, showFilter, fetchParamsAgentesGETData }: any) => {

    const Lang = useLang()

    return (
        <>
            <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={4}>

                    <Grid item xs={12} md={6} lg={6}>
                        <InputText
                            label={`${Lang('DESCRIPCION')} / ${Lang('OBSERVACIONES')}:`}
                            name='magic'
                            value={formValuesSearch.magic}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 w-100'
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        {/* <InputSelect
                            label={Lang('EXCLUIR_DEFICIENCIAS_IMPORTADAS') + ':'}
                            name='excluir_incluido'
                            value={formValuesSearch.excluir_incluido}
                            values={[
                                { id: 'true', name: 'Si' },
                                { id: 'false', name: 'No' },
                            ]}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 '
                        /> */}

                        <InputChecks
                            label={Lang('DEFICIENCIAS')}
                            name='excluir_incluido'
                            valuesDefault={[
                                { id: 'excluir_incluido', label: Lang('EXCLUIR_DEFICIENCIAS_IMPORTADAS') }
                            ]}
                            values={formValuesSearch.excluir_incluido}
                            onChange={handleInputChangeSearch}
                        />


                    </Grid>
                </Grid>

            </Box>
        </>
    )
}
