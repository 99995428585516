// import queryString from 'query-string';

import { cloneDeep, isEmpty } from "lodash"

export function getMsgInputServerColor(objParams, name) {
  return objParams?.error?.response?.data?.data?.[name]
}

export function getMsgInputColor(objParams, name) {
  return objParams[name] && !objParams[name]?.isOk
}

export function getValuesParams(objValuesSearch) {
  let urlParams = {}

  for (const key of Object.keys(objValuesSearch)) {
    if (objValuesSearch[key] !== '') {

      urlParams[key] = objValuesSearch[key]
    }
  }

  return urlParams
}

export function getURLQuery(objUrlParams) {
  return Object.keys(objUrlParams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(objUrlParams[k])).join('&');
}

export function isOrderPrev_(effectTempIndex, effectTempIndex1) {
  return (effectTempIndex.indexOf('orderPrev-') !== -1) || (effectTempIndex1.indexOf('orderPrev-') !== -1)
}

export function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


export function getObjectKeysUsed(obj) {

  const objTemp = {}

  for (const key in obj) {
    if (obj[key]) {
      objTemp[key] = obj[key]
    }
  }

  return objTemp

}

export function getObjectKeysMultilineUsed(obj) {

  const objTemp = {}

  for (const key in obj) {
    if (key.indexOf('.') !== -1) {
      objTemp[key] = obj[key]
    }
  }

  return objTemp

}

export function getSelectByKeys(staticService) {

  const res = Object.keys(staticService).map((key) => {
    return {
      id: key,
      nombre: staticService[key]
    }
  })

  return res
}

export function getBaseImp(cantidad, precio, porcentajedescuento) {

  return Number(cantidad) * Number(precio) - (Number(porcentajedescuento) * (Number(cantidad) * Number(precio)) / 100)
}

export function getDateInput() {
  // Fecha de registro (hoy)
  const date2 = new Date()
  return `${date2.getFullYear()}-${("0" + (date2.getMonth() + 1)).slice(-2)}-${("0" + date2.getDate()).slice(-2)}`

}


export function getDataFile(fetchData, handleReset, nombre = 'default', extension = 'pdf') {

  if (fetchData.data) {
    // 2. Create blob link to download
    const docData = fetchData.data

    const filename = docData?.headers?.['content-disposition']?.split('filename=')?.[1] || `${nombre}.${extension}`
    const blobData = docData?.data

    const instanceBlob = new Blob([blobData])

    const date = new Date()

    const url = window.URL.createObjectURL(instanceBlob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}_${filename}`);
    // 3. Append to html page
    document.body.appendChild(link);

    // 4. Force download
    link.click();

    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);

    if (handleReset) {

      handleReset()
    }

  }
}

export function openLinkByClick(url) {

  // window.open(url)

  const link = document.createElement('a');

  link.href = url;
  link.target = '_blank';

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
}

export function getBase64(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {

  };
  reader.onerror = function (error) {
    // console.log('Error: ', error);
  };
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
};

export function isStatusSuccess(status) {

  return String(status)?.[0] === '2'
}

export function isStatusError(status) {

  return String(status)?.[0] === '4' || String(status)?.[0] === '5'
}

export function fixHourWithZero(data) {

  return String(data)?.length === 1 ? '0' + String(data) : String(data)
}

export function getParseDate(data) {

  return `${data.getFullYear()}-${fixHourWithZero(data.getMonth() + 1)}-${fixHourWithZero(data.getDate())}`
}

export function getParseHours(data) {

  return fixHourWithZero(data.getHours()) + ':' + fixHourWithZero(data.getMinutes())
}

export function setUrlByParams(urlParams, ...exceptKeys) {

  const urlParamsTemp = cloneDeep(urlParams)

  for (let i = 0; i < exceptKeys.length; i++) {

    delete urlParamsTemp[exceptKeys[i]]
  }

  const query = getURLQuery(urlParamsTemp)

  window.history.replaceState(null, '', `?${query}`);
}


export function clearEmptyKeys(object) {

  let objectTemp = cloneDeep(object)

  Object.keys(objectTemp).forEach((k) => objectTemp[k] === '' && delete objectTemp[k]);

  return objectTemp
}

export function perteneceIDRemesa(fetchData, idCondicionPago) {

  const listIdsCondiciones = fetchData?.data?.items?.map(item => item.id)

  // console.log('listIdsCondiciones', fetchData?.data?.items, idCondicionPago);

  return listIdsCondiciones?.includes(idCondicionPago) || false

}

export function convertParamToObject(list = {}) {

  const obj = {}

  for (let i = 0; i < list?.length; i++) {
    obj[list[i]['id']] = list[i]['name']
  }

  return obj
}

// export function convertListToObject(list = []) {

//   console.log('list', list);

//   const obj = {}

//   for (let i = 0; i < list?.length; i++) {
//     obj[list[i]['id']] = list[i]['name']
//   }

//   return obj
// }

export function convertStaticServiceToArray(staticServices) {

  return Object.keys(staticServices).map((key) => {
    return {
      id: key,
      name: staticServices[key]
    }
  })
}



// Perfiles

export function isAccesoRestringido(user) {
  return user.perfil === 'acceso_restringido'
}

export function isUsuarioNormal(user) {
  return user.role === 'usuario_normal' && !user.is_colegiado
}

// export function isColegiado(user) {
//   return user.role === 'usuario_normal' && user.is_colegiado
// }

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}


export function partition(arr, length) {
  let rest = arr.length % length;
  let size = Math.floor(arr.length / length);
  let j = 0;
  return Array.from({ length }, (_, i) => arr.slice(j, j += size + (i < rest)));
}


// Roles Edificio

export function isPropietarioEdificioVirtual(listRoles = []) {

  return !isEmpty(listRoles.find(item => item.id === 10))
}

export function isGestorMantenimiento(listRoles = []) {

  return !isEmpty(listRoles.find(item => item.id === 1))
}

export function isTecnicoRedactor(listRoles = []) {

  return !isEmpty(listRoles.find(item => item.id === 2))
}

export function isAdministradorFincas(listRoles = []) {

  return !isEmpty(listRoles.find(item => item.id === 3))
}

export function isJuntaComunidadPropietarios(listRoles = []) {

  return !isEmpty(listRoles.find(item => item.id === 4))
}

export function isPropietario(listRoles = []) {

  return !isEmpty(listRoles.find(item => item.id === 5))
}

export function isPromotor(listRoles = []) {

  return !isEmpty(listRoles.find(item => item.id === 6))
}

// Roles app

export function isAdministrador(rol) {

  return rol === 'Ares' || rol === 'Administrador' || rol === 'Cateb'
}

export function isAdministradorAres(rol) {

  return rol === 'Ares'
}


export function isChecked(array) {

  return array?.length === 1
}

export function isEdificioObraNueva(tipoEdificio) {

  // 0: "Edificio nuevo plurifamiliar (OBRA NUEVA)"
  // 1: "Vivienda nueva unifamiliar (OBRA NUEVA)"
  // 2: "Edificio existente plurifamiliar"
  // 3: "Vivienda existente unifamiliar"
  // 4: "Edificio nuevo terciario (OBRA NUEVA)"

  return tipoEdificio === 0 || tipoEdificio === 1 || tipoEdificio === 4
}

export function isFetchDataInitLoader(fetchData) {

  return fetchData?.loading === null || fetchData?.loading === true
}

export function getSoloTiposEdificioPRO(params) {

  return params.filter(item => item.id !== '0' && item.id !== '1' && item.id !== '4')
}

export function isEdificioTerciario(tipo = '') {

  return String(tipo) === '4'
}

export function convertTextErrorServer(string) {

  const locale = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_LANGUAGE') || process.env.REACT_APP_LANGUAGE_DEFAULT || 'ES'

  const textLanguageExternal = {
    'ES': {
      'CAMPO_NO_VALIDO': 'El campo no es válido.',
      'CAMPO_OBLIGATORIO': 'El campo es obligatorio.',
    },
    'CA': {
      'CAMPO_NO_VALIDO': 'El camp no és vàlid.',
      'CAMPO_OBLIGATORIO': 'El camp és obligatori.',
    }
  }

  const textRes = textLanguageExternal[locale]

  if (string.indexOf('no es válido') !== -1) {

    return textRes['CAMPO_NO_VALIDO']

  } else if (string.indexOf('es obligatorio') !== -1) {

    return textRes['CAMPO_OBLIGATORIO']

  } else {

    return textRes['CAMPO_NO_VALIDO']
  }
}

export function parseMoneda(number = 0, minimumFractionDigits = 0) {

  // if ((number === 0 || number === '0')) return ''

  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    useGrouping: "always",
    currency: 'EUR',
    minimumFractionDigits: 0
  }).format(number)
}

export function fetchDataListRolesPrefilter(fetchDataList = []) {

  // id Perfil promotor
  const idPromotor = 6

  return fetchDataList?.filter((item) => item.id !== idPromotor)
}

export function existeDiferenciaMinimaEntreFechas(fecha1, fecha2, diasMinimos) {

  // Formato de fechas: anio-mes-dia

  let fechaInicio = new Date(fecha1).getTime();
  let fechaFin = new Date(fecha2).getTime();

  let diferenciaDias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24)

  // console.log('existeDiferenciaMinimaEntreFechas', fecha1, fecha2, diferenciaDias);

  return diferenciaDias >= diasMinimos
}

export function isEdificioPlurifamiliar(tipo = '') {

  return String(tipo) === '2' || String(tipo) === '0'
}

export function isViviendaUnifamiliar(tipo = '') {

  return String(tipo) === '3' || String(tipo) === '1'
}

export function paramsDocumentacionPrefilter(list = [], tipoDoc = '', tipoEdificio, isVersionExtendida) {

  // var listTemp = sendToLastKeyOtros(list)
  var listTemp = list?.filter(item => item.id !== 'manual_manteniment_habitatges')

  if (isVersionExtendida) return listTemp

  if (isEdificioObraNueva(tipoEdificio)) {

    if (tipoDoc === 'edif_doc_tecnica') {

      return listTemp.filter(item => !(
        item.id === 'cee_estat_actual' ||
        item.id === 'cee_proposta_millores' ||
        item.id === 'certificacions_accesabilidad_utilizacion' ||
        item.id === 'informe_evaluacion_edificio' ||
        item.id === 'informe_verificacion_ite' ||
        item.id === 'inspeccion_tecnica' ||
        item.id === 'manual_manteniment_habitatges' ||
        item.id === 'plan_actuacion'
      ))

    } else if (tipoDoc === 'edif_doc_admin') {

      return listTemp.filter(item => !(
        item.id === 'certificado_aptitud' ||
        item.id === 'certificats_final_obra' ||
        item.id === 'comunicat_local_municipi' ||
        item.id === 'document_acreditatiu_lliurament' ||
        item.id === 'documents_justifiquin_operacions' ||
        item.id === 'pressupostos_i_contractes_obres' ||
        item.id === 'programa_rehabilitacio'
      ))

    } else if (tipoDoc === 'edif_doc_juridica') {

      return listTemp.filter(item => !(
        item.id === 'carregues_reals' ||
        item.id === 'entitats_jurídiques' ||
        item.id === 'regim_especial' ||
        item.id === 'regim_propietat' ||
        item.id === 'acta_reunion_propietarios'
      ))
    }

  } else {

    if (tipoDoc === 'edif_doc_tecnica') {

      return listTemp.filter(item => !(
        item.id === 'certificacion_energetica_obra_acabada' ||
        item.id === 'descripcion_productos_equipos_sistemas' ||
        item.id === 'esquemas_redes_instalaciones' ||
        item.id === 'manual_manteniment_habitatges' ||
        item.id === 'plan_actuacion' ||
        item.id === 'planols_as_built' ||
        item.id === 'recomendaciones_genericas_emergencias'
      ))

    } else if (tipoDoc === 'edif_doc_admin') {

      return listTemp.filter(item => !(
        item.id === 'acta_recepcion_definitiva' ||
        item.id === 'ayudas_beneficios_otorgadas' ||
        item.id === 'cargas_reales_existentes' ||
        item.id === 'cfo_anexos' ||
        item.id === 'declaracio_obra_nova' ||
        item.id === 'docs_justifican_intervenciones' ||
        item.id === 'documento_acreditativo_garantia' ||
        item.id === 'escritura_publica_declaracion_obra_nueva' ||
        item.id === 'escrituras' ||
        item.id === 'llicencies' ||
        item.id === 'marcaje_ce_ascensores' ||
        item.id === 'polisas_de_seguros'
      ))

    } else if (tipoDoc === 'edif_doc_juridica') {

      if (!isEdificioPlurifamiliar(tipoEdificio)) {
        listTemp = listTemp.filter(item => !(item.id === 'acta_reunion_propietarios'))
      }

      return listTemp.filter(item => !(
        item.id === 'carregues_reals' ||
        item.id === 'entitats_jurídiques' ||
        item.id === 'garanties_constructor' ||
        item.id === 'garanties_installacions' ||
        item.id === 'garanties_promotor' ||
        item.id === 'regim_especial' ||
        item.id === 'regim_propietat'
      ))
    }

  }

  return listTemp

}

export function sendToLastKeyOtros(list = []) {

  var index = list.findIndex(item => item?.id === 'doc_tecnica_otros' || item?.id === 'doc_admin_otros' || item?.id === 'edif_doc_juridica_otros_docs')

  if (index !== -1) {

    list.push(list.splice(index, 1)[0])
  }

  return list
}

export function prefilterNodoHijos(hijos = []) {

  // console.log('hijos', hijos)
  if (hijos === null) return null

  var hijosTemp = hijos?.map(item => {

    if (item.admite_hijos && isEmpty(item.hijos) && isEmpty(item.metricas)) {
      return null
    }

    return item
  })

  return hijosTemp.filter(item => item)
}

export function findGetParameter(parameterName) {
  var result = null,
    tmp = [];
  var items = window.location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }

  return result;
}

export function removeParam(key, sourceURL) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}


export function openLinkByClickConvenios(urlOriginal) {

  // window.open(url)

  let url = urlOriginal

  if (url.indexOf('http') === -1) {
    url = `//${url}`
  }

  const link = document.createElement('a');

  link.href = url;
  link.target = '_blank';

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
}

export function isBetweenDates(fechaInicio, fechaFin) {

  const fechaActual = new Date();

  const inicio = new Date(fechaInicio);
  let fin = new Date(fechaFin);

  fin.setDate(fin.getDate() + 1);

  return fechaActual >= inicio && fechaActual <= fin;
}

export function agregarRol(list = [], obj) {

  const listTemp = cloneDeep(list)

  if (!(listTemp.find((item) => String(item.id) === String(obj.id)))) {

    return [
      obj,
      ...listTemp
    ]
  }

  return listTemp

}

export function esFechaMenor(fecha1, fecha2) {
  const f1 = new Date(fecha1);
  const f2 = new Date(fecha2);

  // Solo comparar Año, Mes y Día
  f1.setHours(0, 0, 0, 0);
  f2.setHours(0, 0, 0, 0);

  return f1 < f2; // Devuelve true si fecha1 es menor que fecha2
}