import { useEffect, useState } from 'react'
import { Button, Typography } from '@mui/material';

import { useLang } from '../../language';

import './Modal.scss'
import { ButtonAdvanced } from '../ButtonAdvanced/ButtonAdvanced';
import ModalAdvanced from '../ModalAdvanced/ModalAdvanced';
import { isStatusSuccess } from '../../helpers/helpers';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ModalConfirm = ({ handleClose, data, onClickOK, onClickKO, fetchData = {}, message,
    textOnClickOk, disableBtnClose, variantClickOk = 'primary', textOnClickKO, title, size = 'md', 
    disableBtn, classNameContainerButtons = '' }: any) => {

    const Lang = useLang()

    useEffect(() => {

        if (isStatusSuccess(fetchData?.status)) {
            handleClose()
        }

    }, [fetchData]);

    const [btnOnClick, setbtnOnClick] = useState(false);

    return (
        <div>
            <ModalAdvanced data={{ value: data.value }} handleClose={handleClose} size={size}>
                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {title || <FontAwesomeIcon icon={faCircleExclamation}  style={{'fontSize' : '2.5rem'}}/>}
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    <div className='text-center' style={{ lineHeight: '1.5' }}>
                        {message}
                    </div>
                </ModalAdvanced.Body>

                <ModalAdvanced.Footer className='border-0 mt-2'>
                    <div className={`d-flex justify-content-between ${classNameContainerButtons}`}>
                        <div>
                            {
                                !disableBtnClose && <Button variant="outlined" onClick={() => {
                                    onClickKO && onClickKO()
                                    handleClose()
                                }}>
                                    {textOnClickKO || Lang('CANCELAR')}
                                </Button>
                            }
                        </div>
                        <ButtonAdvanced
                            variant={variantClickOk} type="submit" className="me-2"
                            handleSubmit={onClickOK} loadings={[fetchData.loading]}
                            value={textOnClickOk} disabled={disableBtn}
                        />
                    </div>
                </ModalAdvanced.Footer>
            </ModalAdvanced>

        </div>
    )
}
