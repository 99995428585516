import { Grid } from "@mui/material";
import { useEffect } from 'react'
import { MantenimientosPreventivosServices } from "./controllers/mantpreventivo.services";
import { TableMantPreventivo } from "./TableMantPreventivo";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { clearEmptyKeys, getValuesParams, setUrlByParams } from "../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../hooks/useForm";

export const MantenimientoPreventivo = ({ idedificio, id }: any) => {

    const [fetchOperaciones, fetchOperacionesData] = MantenimientosPreventivosServices.GET_BY_EDIFICIO()

    // Buscador de agentes

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            order: '',
            elemento_constructivo_id: id,
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        elemento_constructivo_id: params.elemento_constructivo_id || allValuesSearch.elemento_constructivo_id,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'elemento_constructivo_id')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchOperaciones({
                id: idedificio,
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'elemento_constructivo_id')

        fetchOperaciones({
            id: idedificio,
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])


    return (
        <Grid container spacing={2} columnSpacing={3}>

            <Grid item xs={12} className="mb-3">
                <TableMantPreventivo
                    data={fetchOperacionesData.data?.items}
                    // data={elementSelected?.operations}
                    // loading={false}
                    loading={fetchOperacionesData.loading}
                    total={fetchOperacionesData.data?.total}
                    perPage={fetchOperacionesData.data?.per_page}

                    formValuesSearch={formValuesSearch}
                    handleFormChange={handleFormChange}
                    handleInputChangeSearch={handleInputChangeSearch}

                    idedificio={idedificio}
                    fetchSuccess={() => {
                        fetchOperaciones({
                            id: idedificio,
                            params: {
                                ...getValuesParams(formValuesSearch)
                            }
                        })
                    }}
                    id={id}
                />
            </Grid>

        </Grid>
    );
};
