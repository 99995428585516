import { useEffect, useState } from "react";

import { Navigate, Route, Routes, useParams } from "react-router";
import { isStatusSuccess } from "../../../../helpers/helpers";
import { isEmpty } from "lodash";
import { Button, Container, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Placeholder } from "../../../../components/Placeholder/Placeholder";
import { ProgramacionGestion } from "./ProgramacionGestion/ProgramacionGestion";
import { useLang } from "../../../../language";
import { useModalData } from "../../../../hooks/useModalData";
import { ModalProyectosFases } from "../../router/ModalProyectosFases";

const ProgGestion = ({ fetchProyectosGETData, readOnly, perfil_llibre }: any) => {

  const { id } = useParams()

  const Lang = useLang()

  const params: any = useParams()

  const [idCurrentProject, setidCurrentProject] = useState<any>();

  const [navLinksProyectos, setnavLinksProyectos] = useState<any>(null);

  useEffect(() => {

    if (isStatusSuccess(fetchProyectosGETData.status)) {

      const data = fetchProyectosGETData?.data?.items || []

      setnavLinksProyectos(data?.map((item: any) => {

        return {
          title: item.nom,
          link: item.id
        }
      }))

    }

    if (fetchProyectosGETData?.error) {

      setnavLinksProyectos([])
    }

  }, [fetchProyectosGETData]);


  useEffect(() => {

    if (params) {

      setidCurrentProject(params['*']?.split('/')?.[0])
    }

  }, [params]);

  const [loaderProject, setloaderProject] = useState(true);

  useEffect(() => {

    setloaderProject(navLinksProyectos === null)

  }, [navLinksProyectos]);


  const [dataModal_Proyectos, handleshowModal_Proyectos, handleCloseModal_Proyectos] = useModalData()

  const idCurrentProjectLocal = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_ID_CURRENT_PROJECT')

  return (
    <>
      {
        loaderProject ?
          <LoaderProyecto />
          :
          <>
            {
              isEmpty(navLinksProyectos) ?
                <div className="w-100">
                  <Typography variant="body1" component="h2" className="text-center pt-5 pb-4">
                    <FontAwesomeIcon icon={faInfoCircle} className="fa-2x mb-3" />
                    <span className="d-block">
                      {Lang('PARRAFO_EDIFICIO_SIN_PERIODOS_AGREGAR_CONTINUAR')}...
                    </span>
                  </Typography>

                  <div className="d-flex">
                    {
                      !readOnly &&
                      <Button className="mx-auto" variant='outlined' onClick={handleshowModal_Proyectos}>
                        <div className='text-center w-100 color-primary'>
                          <FontAwesomeIcon icon={faEdit} className='me-1' /> {Lang('PERIODOS_Y_FASES')}
                        </div>
                      </Button>
                    }
                  </div>

                </div>
                :
                <Routes>

                  <>
                    {
                      navLinksProyectos?.map((item: any) => {

                        return (
                          <Route
                            key={item.link}
                            path={`${item.link}/*`}
                            element={
                              <ProgramacionGestion
                                idedificio={id}
                                idCurrentProject={idCurrentProject}
                                readOnly={readOnly}
                                perfil_llibre={perfil_llibre}
                              />
                            }
                          />
                        )
                      })
                    }

                    {
                      (navLinksProyectos.find((item: any) => String(item.link) === String(idCurrentProjectLocal))
                        || navLinksProyectos?.[0]) &&
                      <Route
                        path="*"
                        element={<Navigate to={String(navLinksProyectos.find((item: any) => String(item.link) === String(idCurrentProjectLocal))?.link || navLinksProyectos?.[0]?.link)} replace />}
                      />
                    }
                  </>

                </Routes>
            }
          </>
      }

      <ModalProyectosFases
        data={dataModal_Proyectos}
        handleClose={handleCloseModal_Proyectos}
        idedificio={id}
      />
    </>
  )
}

export default ProgGestion;


const LoaderProyecto = () => {
  return (
    <>
      <Container maxWidth={false} className='mt-3'>

        <Grid container spacing={3}>

          <Grid item xs={12} className='py-0 py-lg-0 mt-3'>
            <Placeholder className='px-0 py-3 delay-1' height='3rem' />
            <Placeholder className='px-0 py-3 delay-2' height='3rem' />
            <Placeholder className='px-0 py-3 delay-3' height='3rem' />
          </Grid>

        </Grid>

      </Container>
    </>
  )
}