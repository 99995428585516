import { FormControl, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { receiveFechaFormat2 } from '../../controllers/controllers';
import { convertTextErrorServer } from '../../helpers/helpers';

export const InputText = ({ label, readOnly, value, validation, onChange, name, type = 'text', className = '',
    localValidation = [], fetchData = {}, disabled = false, defaultValue = '', readOnlyMU, onBlur, focused,
    minValue, maxValue, onFocus, nameFetchData, enablePrefilterError = true, startAdornment, endAdornment,
    classNameInput = '' 
}: any) => {

    const [validations, setvalidations] = useState({});

    useEffect(() => {

        setvalidations(validated(localValidation))

    }, [...(localValidation?.map((item: any) => item.isOk))]);

    useEffect(() => {

        if (fetchData?.error) {

            if (fetchData?.error?.code === 422) {

                const errorsByName = fetchData?.error?.errors?.[nameFetchData || name]

                if (errorsByName) {

                    setvalidations(validatedServer(errorsByName, enablePrefilterError))
                }

            } else {

                setvalidations(validatedServer([], enablePrefilterError))
            }
        }

        if (fetchData?.data) {
            
            setvalidations(validatedServer([], enablePrefilterError))
        }

    }, [fetchData]);

    let objInputProps: any = {}

    if (readOnlyMU !== undefined) {
        objInputProps.readOnly = readOnlyMU
    }

    if (startAdornment !== undefined) {
        objInputProps.startAdornment = startAdornment
    }

    if (endAdornment !== undefined) {
        objInputProps.endAdornment = endAdornment
    }


    const onChangeMiddleware = (e: any) => {

        if (type === 'number') {

            if (minValue !== undefined) {

                e.target.value = Number(e.target.value) < minValue ? minValue : Number(e.target.value)
            }

            if (maxValue !== undefined) {

                e.target.value = Number(e.target.value) > maxValue ? maxValue : Number(e.target.value)
            }
        }

        onChange(e)
    }


    return (
        <FormControl fullWidth variant="standard">
            {
                readOnly ?
                    <>
                        <Typography variant='body2' className='mb-1' component="span">
                            {label}
                        </Typography>
                        <Typography variant='body1' className={`my-0 pb-1 ${classNameInput}`} component="span">
                            {type === 'date' ? (receiveFechaFormat2(value || '') || '-') : (value)}
                        </Typography>
                    </> :
                    <>
                        {/* <InputLabel htmlFor="standard-adornment-amount">
                            {label}
                        </InputLabel> */}
                        <TextField
                            variant="standard"
                            value={value}
                            label={label}
                            onChange={onChangeMiddleware}
                            name={name}
                            type={type}
                            {...validations}
                            className={`${className} ${readOnlyMU ? 'pe-none' : ''}`}
                            disabled={disabled}
                            defaultValue={defaultValue}
                            InputProps={{ ...objInputProps }}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            focused={focused}
                        />
                    </>
            }
        </FormControl>
    )
}

const validated = (localValidation: any) => {

    let localValidationPreFilter = localValidation?.filter((item: any) => item.isOk === false) || []

    let res = {}

    if (localValidationPreFilter.length > 0) {
        res = {
            helperText: localValidationPreFilter?.map((item: any, index: number) => {
                return <span className='d-block' key={index}>{item.msgError}</span>
            }),
            error: true
        }
    }

    return res
}

const validatedServer = (errorsByName: any, enablePrefilterError: boolean) => {

    let localValidationPreFilter = errorsByName

    let res = {}

    if (localValidationPreFilter.length > 0) {
        res = {
            helperText: localValidationPreFilter?.map((item: any, index: number) => {
                return <span className='d-block' key={index}>{enablePrefilterError ? convertTextErrorServer(item) : item}</span>
            }),
            error: true
        }
    }

    return res
}

