import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../helpers/validations'
import { isNumber } from 'lodash'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputTextarea } from "../../../../../../../components/Inputs/InputTextarea"
import { MejoraFaseServices } from "./controllers/fases.services"

export const ModalFase = ({ data, handleClose, fetchParamsAgentesGETData, idedificio, fetchSuccess,
    formValuesSearch, fetchParamsDocumentosGETData }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchFaseGET, fetchFaseGETData] = MejoraFaseServices.GET()

    const [fetchFasePOST, fetchFasePOSTData] = MejoraFaseServices.POST()

    const [fetchFasePATCH, fetchFasePATCHData] = MejoraFaseServices.PATCH()

    const [fetchFaseDELETE, fetchFaseDELETEData] = MejoraFaseServices.DELETE()

    const [formFase, handleInputFase, handleFormFase, resetFormFase] = useForm({
        fase: '',
        orden: '',
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        fase: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        // tipus_document: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchFaseGETData.status)) {

            handleFormFase({
                ...fetchFaseGETData.data
            })
        }

    }, [fetchFaseGETData]);


    // ------------ POST

    const handleSubmitDocumentoPOST = () => {

        if (handleValidDocumento(formFase)) {

            fetchFasePOST({
                body: {
                    ...formFase,
                    edifici_id: idedificio
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchFasePOSTData.status)) {
            fetchSuccess()
            handleClose()
            window.location.reload()

        }

    }, [fetchFasePOSTData]);


    // ------------ PATCH Agente

    const handleSubmitDocumentoPATCH = () => {

        if (handleValidDocumento(formFase)) {

            fetchFasePATCH({
                id: data?.id,
                body: {
                    ...formFase,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchFasePATCHData.status)) {
            fetchSuccess()
            handleClose()
            window.location.reload()
        }

    }, [fetchFasePATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchFaseDELETE({
            id: data?.id,
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchFaseDELETEData.status)) {
            fetchSuccess()
            handleClose()
            window.location.reload()
        }

    }, [fetchFaseDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (isNumber(data?.id)) {

                fetchFaseGET({
                    id: data?.id
                })
            }

        } else {
            resetFormFase()
            resetvalidDocumento()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='md'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVA_FASE') : Lang('EDITAR_FASE')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    {
                        fetchFaseGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('NOMBRE') + ': *'}
                                            name='fase'
                                            value={formFase.fase}
                                            onChange={handleInputFase}
                                            fetchData={data?.action === 'CREATE' ? fetchFasePOSTData : fetchFasePATCHData}
                                            localValidation={validDocumento.fase}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={5}>
                                        <InputText
                                            label={Lang('ORDEN_DE_VISUALIZACION') + ':'}
                                            name='orden'
                                            type='number'
                                            value={formFase.orden}
                                            onChange={handleInputFase}
                                            fetchData={data?.action === 'CREATE' ? fetchFasePOSTData : fetchFasePATCHData}
                                            localValidation={validDocumento.orden}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('OBSERVACIONES') + ':'}
                                            name='observacions'
                                            value={formFase.observacions}
                                            onChange={handleInputFase}
                                            fetchData={data?.action === 'CREATE' ? fetchFasePOSTData : fetchFasePATCHData}
                                            localValidation={validDocumento.observacions}
                                        />
                                    </Grid> */}

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubmitDocumentoPOST}
                                loadings={[fetchFasePOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className="mt-3"
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchFaseDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handleSubmitDocumentoPATCH}
                                    loadings={[fetchFasePATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchFasePOSTData} />

            <ModalStatusServer fetchData={fetchFasePATCHData} />

            <ModalStatusServer fetchData={fetchFaseDELETEData} />
        </>
    )
}