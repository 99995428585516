import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";
import { useLang } from "../../../../../../language";
import { MesesStaticServices, TiposPlanFinancieraStaticServices } from "../../../../../../controllers/statics/statics.services";
import { useForm } from "../../../../../../hooks/useForm";
import { PlanificacionFinancieraServices } from "../../controllers/elemconst.services";
import { receiveFecha, receiveFechaFormat2 } from "../../../../../../controllers/controllers";
import { getDataFile, isFetchDataInitLoader, isStatusSuccess, parseMoneda } from "../../../../../../helpers/helpers";
import { ButtonAdvanced } from "../../../../../../components/ButtonAdvanced/ButtonAdvanced";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { ModalStatusServer } from "../../../../../../components/Modals/ModalStatusServer";

export const ResumenFinanciero = ({ idCurrentProject, readOnly }: any) => {

    const Lang = useLang()

    const [fetchResumenFinancieroGET, fetchResumenFinancieroGETData] = PlanificacionFinancieraServices.GET_RESUMEN_FINANCIERO(idCurrentProject)

    const [fetchCalendarioFinancieroGET, fetchCalendarioFinancieroGETData] = PlanificacionFinancieraServices.GET_CALENDARIO_FINANCIERO(idCurrentProject)

    useEffect(() => {

        fetchResumenFinancieroGET()

        fetchCalendarioFinancieroGET()

    }, []);

    const [dataResumen, setdataResumen] = useState<any>([]);

    const [dataCalendarioResumen, setdataCalendarioResumen] = useState<any>({});

    const mesesStaticServices: any = MesesStaticServices.GET()

    const tiposPlanFinancieraStaticServices: any = TiposPlanFinancieraStaticServices.GET()

    useEffect(() => {

        if (isStatusSuccess(fetchCalendarioFinancieroGETData.status)) {

            const res = fetchCalendarioFinancieroGETData?.data || {}

            let anios: any = new Set([...Object.keys(res['gastos']), ...Object.keys(res['ingresos']), ...Object.keys(res['tesoreria'])])

            anios = [...anios]

            const resFinal: any = {}

            for (let i = 0; i < anios.length; i++) {

                resFinal[anios[i]] = {}

                resFinal[anios[i]]['ingresos'] = res['ingresos'][anios[i]] || {}
                resFinal[anios[i]]['gastos'] = res['gastos'][anios[i]] || {}
                resFinal[anios[i]]['tesoreria'] = res['tesoreria'][anios[i]] || {}
            }

            setdataCalendarioResumen(resFinal)
        }

    }, [fetchCalendarioFinancieroGETData]);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: '',
    });

    // ------------ Descargar resumen financiero

    const [fetchGenerarDocGETBlob, fetchGenerarDocGETBlobData, resetfetchGenerarDocGETBlobData] = PlanificacionFinancieraServices.GET_RESUMEN_FINANCIERO_BLOB(idCurrentProject)

    const handleSubmitGenerarVistaPrevia = () => {

        fetchGenerarDocGETBlob({
            params: {
                toDownload: true
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGenerarDocGETBlobData.status)) {

            getDataFile(fetchGenerarDocGETBlobData, resetfetchGenerarDocGETBlobData, 'resumen_financiero')
        }

    }, [fetchGenerarDocGETBlobData]);

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-between align-items-end  mb-3">
                <Typography variant="h6" component="h2" className="text-start mb-0">
                    {Lang('RESUMEN_FINANCIERO')} ({Lang('GASTOS')})
                </Typography>
                <ButtonAdvanced
                    variant="contained" type="button" className="ms-auto" size="small"
                    handleSubmit={handleSubmitGenerarVistaPrevia}
                    loadings={[fetchGenerarDocGETBlobData.loading]}
                    value={Lang('DESCARGAR_RESUMEN_FINANCIERO')} icon={<FontAwesomeIcon icon={faFilePdf} className='me-2' />}
                />
            </div>

            <Grid container spacing={3} rowSpacing={3}>

                <Grid item xs={12} md={12} lg={12}>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="fw-bold">{Lang('FASE')}</TableCell>
                                    <TableCell className="text-center fw-bold">{Lang('FECHA_INICIO')}</TableCell>
                                    <TableCell className="text-center fw-bold">{Lang('FECHA_FIN')}</TableCell>
                                    <TableCell className="text-center fw-bold">{Lang('TOTAL_IMPORTE_DE_OBRAS')}</TableCell>
                                    <TableCell className="text-center fw-bold">{Lang('TOTAL_IMPORTE_DE_HONORARIOS')}</TableCell>
                                    <TableCell className="text-center fw-bold">{Lang('TOTAL_IMPORTE_DE_TASAS')}</TableCell>
                                    <TableCell className="text-center fw-bold">{Lang('TOTAL_IMPORTE_OTROS')}</TableCell>
                                    <TableCell className="text-center fw-bold">{Lang('TOTAL_FASE')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    isFetchDataInitLoader(fetchResumenFinancieroGETData) ?
                                        <TableAdvancedLoader />
                                        :
                                        <>
                                            {
                                                fetchResumenFinancieroGETData?.data?.map((item: any, index: number) => {

                                                    if (fetchResumenFinancieroGETData?.data?.length - 1 === index) {

                                                        return null
                                                    }

                                                    return (
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index} >
                                                            <TableCell className="text-nowrap">{item.nom}</TableCell>
                                                            <TableCell className="text-nowrap text-center">{receiveFechaFormat2(receiveFecha(item.data_inici_calculada))}</TableCell>
                                                            <TableCell className="text-nowrap text-center">{receiveFechaFormat2(receiveFecha(item.data_fi_calculada))}</TableCell>
                                                            <TableCell className="text-nowrap text-center">{parseMoneda(item.import_obres)}</TableCell>
                                                            <TableCell className="text-nowrap text-center">{parseMoneda(item.import_honoraris)}</TableCell>
                                                            <TableCell className="text-nowrap text-center">{parseMoneda(item.import_taxes)}</TableCell>
                                                            <TableCell className="text-nowrap text-center">{parseMoneda(item.import_altres)}</TableCell>
                                                            <TableCell className="text-nowrap text-center">{parseMoneda(item.import_total)}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }

                                        </>
                                }

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell className="text-nowrap"></TableCell>
                                    <TableCell className="text-nowrap text-center"></TableCell>
                                    <TableCell className="text-nowrap text-center"></TableCell>
                                    <TableCell className="text-nowrap text-center"></TableCell>
                                    <TableCell className="text-nowrap text-center"></TableCell>
                                    <TableCell className="text-nowrap text-center"></TableCell>
                                    <TableCell className="text-nowrap text-end fw-bold"> {Lang('TOTAL')}:</TableCell>
                                    <TableCell className="text-nowrap text-center">{parseMoneda(fetchResumenFinancieroGETData?.data?.[fetchResumenFinancieroGETData?.data?.length - 1]?.import_total_general)}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>

            <div className="d-flex justify-content-between align-items-end mt-4 mb-3">
                <Typography variant="h6" component="h2" className="text-start mb-0">
                    {Lang('CALENDARIO_PLANIFICACION_FINANCIERA')}
                </Typography>

                <Box component="form" noValidate autoComplete="off" className="flex-grow-1">
                    <Grid container spacing={3} justifyContent='end'>

                        {/* <Grid item xs={12} md={4} lg={4}>
                            <InputText
                                label={`${Lang('ELEMENTOS')} / ${Lang('DESCRIPCION')}:`}
                                name='magic'
                                value={formValuesSearch.magic}
                                onChange={handleInputChangeSearch}
                                className='mt-2 mb-3 w-100'
                            />
                        </Grid> */}

                        {/* <Grid item xs={12} md={4} lg={3}>
                            <InputSelect
                                label={Lang('ANIO') + ':'}
                                name='idfase'
                                value={formValuesSearch.idfase}
                                values={[
                                    {
                                        id: 0, name: '2023'
                                    },
                                    {
                                        id: 1, name: '2024'
                                    },
                                    {
                                        id: 2, name: '2025'
                                    },
                                ]}
                                onChange={handleInputChangeSearch}
                                className=''
                            />

                        </Grid> */}

                    </Grid>

                </Box>

            </div>

            <Grid container spacing={3} rowSpacing={3} className="mb-4">

                <Grid item xs={12} md={12} lg={12}>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="fw-bold"></TableCell>
                                    <TableCell className="fw-bold"></TableCell>
                                    {
                                        Object.keys(mesesStaticServices)?.map((key: string) => {
                                            return (
                                                <TableCell className="fw-bold">{mesesStaticServices[key]}</TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    isEmpty(fetchCalendarioFinancieroGETData) ?
                                        <TableAdvancedLoader />
                                        :
                                        <>
                                            {
                                                Object.keys(dataCalendarioResumen)?.map((key: any) => {
                                                    return (
                                                        <>
                                                            {
                                                                Object.keys(dataCalendarioResumen[key])?.map((key2: any, index2: number) => {
                                                                    return (
                                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className="border-bottom" key={key + '_' + index2}>
                                                                            {
                                                                                index2 === 0 && <TableCell rowSpan={3} className="text-nowrap text-center border-bottom-2">{key}</TableCell>
                                                                            }
                                                                            <TableCell className={`text-nowrap ${key2 === 'tesoreria' ? 'border-bottom-2' : ''}`}>{tiposPlanFinancieraStaticServices[key2]}</TableCell>

                                                                            {
                                                                                Object.keys(mesesStaticServices)?.map((keyMeses: string, index3: number) => {
                                                                                    return (
                                                                                        <TableCell className={`text-nowrap text-center ${key2 === 'tesoreria' ? 'border-bottom-2' : ''}`} key={key + '_' + index2 + '_' + index3}>
                                                                                            {
                                                                                                (key2 === 'ingresos' || key2 === 'gastos') && !dataCalendarioResumen[key][key2]?.[keyMeses] ? '' :
                                                                                                <span className={`${Number(dataCalendarioResumen[key][key2]?.[keyMeses]) < 0 ? 'color-red' : ''}`}>
                                                                                                    {parseMoneda(dataCalendarioResumen[key][key2]?.[keyMeses])}
                                                                                                </span>
                                                                                            }
                                                                                        </TableCell>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </TableRow>
                                                                    )
                                                                })

                                                            }
                                                        </>

                                                    )
                                                })
                                            }

                                        </>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>

            <ModalStatusServer fetchData={fetchGenerarDocGETBlobData} onlyError />

        </div>
    )
};


const TableAdvancedLoader = () => {

    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-1' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-2' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-3' height='1.1rem' />
                </TableCell>
            </TableRow>
        </>
    )
}