import { Box, Grid } from '@mui/material'
import { InputText } from '../../../../../../../components/Inputs'
import { useLang } from '../../../../../../../language'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import { InputChecks } from '../../../../../../../components/Inputs/InputChecks'
import { convertStaticServiceToArray } from '../../../../../../../helpers/helpers'

export const FormSimple = ({ formValuesSearch, handleInputChangeSearch, fetchMejoraNivelPrincipalData, fetchMejoraFasesData }: any) => {

    const Lang = useLang()

    return (
        <>
            <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={4}>

                    <Grid item xs={12} md={4} lg={4} xl={3}>
                        <InputText
                            label={`${Lang('ELEMENTOS')} / ${Lang('DESCRIPCION')}:`}
                            name='magic'
                            value={formValuesSearch.magic}
                            onChange={handleInputChangeSearch}
                            className='w-100'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3} xl={2}>
                        <InputSelect
                            label={Lang('FASE') + ':'}
                            name='idfase'
                            value={formValuesSearch.idfase}
                            values={fetchMejoraFasesData?.data?.items || []}
                            onChange={handleInputChangeSearch}
                            className=''
                        />

                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <InputSelect
                            label={'Requisito:'}
                            name='idnivelprincipal'
                            value={formValuesSearch.idnivelprincipal}
                            values={convertStaticServiceToArray(fetchMejoraNivelPrincipalData?.data?.data || {})}
                            onChange={handleInputChangeSearch}
                            className=''
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3} xl={2}>
                        <InputChecks
                            name='excluir_asignados'
                            values={formValuesSearch.excluir_asignados}
                            valuesDefault={[
                                {
                                    id: 'excluir_asignados',
                                    label: Lang('EXCLUIR_ASIGNADOS')
                                }
                            ]}
                            onChange={handleInputChangeSearch}
                        />
                    </Grid>

                </Grid>

            </Box>
        </>
    )
}
