import { useState, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { useLang } from "../../../../../../language";
import { MantenimientoCorrectivo } from "./Deficiencias";
import { MantenimientoCorrectivoITE } from "./DeficienciasFicheroITE";
import { isStatusSuccess } from "../../../../../../helpers/helpers";
import { Grid, Card, CardContent } from "@mui/material";
import { useForm } from "../../../../../../hooks/useForm";
import { MantCorrectDeficienciasServices } from "./Deficiencias/controllers/mantpreventivo.services";
import queryString from 'query-string';
import { FicheroITEServices } from "./DeficienciasFicheroITE/controllers/ficheroite.services";

export const MantenimientoCorrectivoIndex = ({ id, idedificio }: any) => {

    const Lang = useLang()

    const navLinks = [
        {
            title: Lang('DEFICIENCIAS'),
            link: 'deficiencias'
        },
    ]

    const [activeNav, setactiveNav] = useState({});


    const [fetchMiElemConst, fetchMiElemConstData] = MantCorrectDeficienciasServices.GET()

    // Buscador de agentes

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            order: '',
            sistema: id,
            edifici_id: idedificio,
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        sistema: params.sistema || allValuesSearch.sistema,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
    });


    
    // Verificar importacion inicial

    const [fetchFicheroITEResumenGET, fetchFicheroITEResumenGETData] = FicheroITEServices.GET_RESUMEN()

    const [isImportado, setisImportado] = useState(false);


    useEffect(() => {

        fetchFicheroITEResumenGET({
            params: {
                edifici_id: idedificio,
            }
        })

    }, []);

    useEffect(() => {

        if (isStatusSuccess(fetchFicheroITEResumenGETData.status)) {

            setisImportado(!!fetchFicheroITEResumenGETData?.data?.id)
        }

    }, [fetchFicheroITEResumenGETData]);


    return (

        <>
            <Grid container spacing={3} className="pt-3">

                <Grid item xs={12}>

                    <Routes>

                        <Route
                            path={`${navLinks[0].link}`}
                            element={
                                <MantenimientoCorrectivo
                                    idedificio={idedificio}
                                    id={id}
                                    fetchMiElemConstData={fetchMiElemConstData}
                                    formValuesSearch={formValuesSearch}
                                    handleFormChange={handleFormChange}
                                    handleInputChangeSearch={handleInputChangeSearch}
                                    fetchMiElemConst={fetchMiElemConst}
                                    setisImportado={setisImportado}
                                    isImportado={isImportado}
                                />
                            }
                        />

                        <Route
                            path={`${navLinks[0].link}/importar-ite`}
                            element={
                                <Grid container spacing={2} columnSpacing={3}>

                                    <Grid item xs={12} >
                                        <Card>
                                            <CardContent>
                                                <MantenimientoCorrectivoITE
                                                    idedificio={idedificio}
                                                    id={id}
                                                    // setactiveImportITE={setactiveImportITE}
                                                    fetchSuccessDeficiencias={
                                                        () => {
                                                            fetchMiElemConst({
                                                                params: {
                                                                    edifici_id: idedificio,
                                                                    sistema: id,
                                                                }
                                                            })
                                                        }
                                                    }
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <Route
                            path="*"
                            element={<Navigate to={navLinks?.[0]?.link} replace />}
                        />

                    </Routes>
                </Grid>

            </Grid>
        </>
    )
};
