import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useModalData } from "../../../../../../../hooks/useModalData";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useLang } from "../../../../../../../language";
import { ModalFase } from "./ModalFase";

export const TableFases = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, idedificio, fetchSuccess, fetchParamsAgentesGETData,
    fetchParamsDocumentosGETData, id, proyectoSelected }: any) => {

    const Lang = useLang()

    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    const allActions = [
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_FASE')}</span>,
            variant: 'contained',
            action: () => handleShowUsuario({
                action: 'CREATE',
            }),
        }
    ]

    const allActionsPerLine: any = [
        {
            variant: 'outlined',
            label: <span><FontAwesomeIcon icon={faEdit} className='' /> {Lang('')}</span>,
            className: 'ms-2',
            size: 'small',
            action: (item: any) => {
                handleShowUsuario({
                    ...item,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('EDITAR')
            },
        },
    ]

    const columnsTable = {
        fase: {
            title: Lang('NOMBRE'),
        },
        orden: {
            title: Lang('ORDEN'),
        },
    }

    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('FASES_DE_MEJORA')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                    classNameTable='min-w-auto'
                />

                {/* <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                /> */}
            </TableAdvanced>

            <ModalFase
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idedificio={idedificio}
                formValuesSearch={formValuesSearch}
                fetchSuccess={fetchSuccess}
                fetchParamsDocumentosGETData={fetchParamsDocumentosGETData}
                id={id}
                proyectoSelected={proyectoSelected}
            />
        </>
    )
}
