import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../helpers/validations'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputTextarea } from '../../../../../../../components/Inputs/InputTextarea'
import { MedidaServices } from './controllers/medidas.services'

export const ModalMedida = ({ data = {}, handleClose, idEdificio, fetchPropiedadAgentesGET,
    formValuesSearch, elemento, fetchSuccess, fetchParamsPublicGETData }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()


    const [fetchMedidaGET, fetchMedidaGETData, resetfetchMedidaGETData] = MedidaServices.GET()

    const [fetchMedidaPOST, fetchMedidaPOSTData, resetfetchMedidaPOSTData] = MedidaServices.POST()

    const [fetchMedidaPATCH, fetchMedidaPATCHData, resetfetchMedidaPATCHData] = MedidaServices.PATCH()

    const [fetchMedidaDELETE, fetchMedidaDELETEData, resetfetchMedidaDELETEData] = MedidaServices.DELETE()

    const [formMedida, handleInputMedida, handleFormMedida, resetFormMedida] = useForm({
        complejidad: '',
        coste: '',
    })

    const [validMedida, handleValidationMedida, resetvalidMedida] = useValidator({
        medida: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        // nom: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // nif: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ GET Agente

    useEffect(() => {

        if (isStatusSuccess(fetchMedidaGETData.status)) {

            handleFormMedida({
                ...fetchMedidaGETData.data
            })
        }

    }, [fetchMedidaGETData]);


    // ------------ POST

    const handleSubmitNuevoAgente = () => {

        if (handleValidationMedida(formMedida)) {

            fetchMedidaPOST({
                id: formMedida.tipo,
                body: {
                    ...formMedida,
                    edifici_id: idEdificio,
                    elemento,
                    seleccionado: true,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMedidaPOSTData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMedidaPOSTData]);


    // ------------ PATCH Agente

    const handleSubmitEditarAgente = () => {

        if (handleValidationMedida(formMedida)) {

            fetchMedidaPATCH({
                id: data?.id,
                body: {
                    ...formMedida,
                    edifici_id: idEdificio
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMedidaPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMedidaPATCHData]);


    // ------------ DELETE

    const handleSubmitEliminarAgente = () => {

        fetchMedidaDELETE({
            id: data?.id,
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMedidaDELETEData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMedidaDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (data?.id) {
                fetchMedidaGET({
                    id: data?.id
                })
            }

        } else {
            resetFormMedida()
            resetvalidMedida()
            resetfetchMedidaGETData()
            resetfetchMedidaPOSTData()
            resetfetchMedidaPATCHData()
            resetfetchMedidaDELETEData()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='lg'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVA_MEDIDA') : Lang('EDITAR_MEDIDA')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        fetchMedidaGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={2} rowSpacing={0}>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('MEDIDA') + ': *'}
                                            name='medida'
                                            value={formMedida.medida}
                                            onChange={handleInputMedida}
                                            fetchData={data?.action === 'CREATE' ? fetchMedidaPOSTData : fetchMedidaPATCHData}
                                            localValidation={validMedida.medida}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('BENEFICIO') + ': *'}
                                            name='beneficio'
                                            value={formMedida.beneficio}
                                            onChange={handleInputMedida}
                                            fetchData={data?.action === 'CREATE' ? fetchMedidaPOSTData : fetchMedidaPATCHData}
                                            localValidation={validMedida.beneficio}
                                        />
                                    </Grid>

                                    {
                                        (data?.isAhorroEnergia && data?.isAhorroEnergia !== 0) &&
                                        <Grid item xs={12} md={3} lg={3}>
                                            <InputText
                                                label={Lang('REDUCCION') + ' Cep,nren (%)'}
                                                name='reduccion_cepnren'
                                                type='number'
                                                value={formMedida.reduccion_cepnren}
                                                onChange={handleInputMedida}
                                                // fetchData={data?.action === 'CREATE' ? fetchMedidaPOSTData : fetchMedidaPATCHData}
                                                localValidation={validMedida.reduccion_cepnren}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12} md={3} lg={4}>
                                        <InputSelect
                                            label={Lang('COSTE')}
                                            name='coste'
                                            type='number'
                                            value={formMedida.coste}
                                            values={fetchParamsPublicGETData?.data?.['tipus_cost_milloras']}
                                            onChange={handleInputMedida}
                                            localValidation={validMedida.coste}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={4}>
                                        <InputSelect
                                            label={Lang('COMPLEJIDAD')}
                                            name='complejidad'
                                            value={formMedida.complejidad}
                                            values={fetchParamsPublicGETData?.data?.['tipus_dificultat_milloras']}
                                            // values={convertStaticServiceToArray(mesesStaticServices)}
                                            onChange={handleInputMedida}
                                            localValidation={validMedida.complejidad}
                                        />
                                    </Grid>

                                    <Grid item xs={12} display='flex' alignItems='center'>
                                        <InputTextarea
                                            label={Lang('COMENTARIOS') + ':'}
                                            name='comentario'
                                            value={formMedida.comentario}
                                            onChange={handleInputMedida}
                                            fetchData={data?.action === 'CREATE' ? fetchMedidaPOSTData : fetchMedidaPATCHData}
                                            localValidation={validMedida.comentario}
                                        />
                                        <Tooltip title={Lang('PARRAFO_CREAR_MEDIDA_COMENTARIO')} placement="top">
                                            <FontAwesomeIcon icon={faQuestionCircle} className="mb-2 ms-2 fs-5" />
                                        </Tooltip>
                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubmitNuevoAgente}
                                loadings={[fetchMedidaPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className="mt-3"
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchMedidaDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handleSubmitEditarAgente}
                                    loadings={[fetchMedidaPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchMedidaPOSTData} />

            <ModalStatusServer fetchData={fetchMedidaPATCHData} />
        </>
    )
}