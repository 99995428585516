import { useEffect, useState } from "react";
import { useLang } from "../../../../../../../language";
import { Button, CircularProgress, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { isStatusSuccess, openLinkByClick } from "../../../../../../../helpers/helpers";
import { DocumentoServices } from "../controllers/documentacion.services";
import { ModalStatusServer } from "../../../../../../../components/Modals/ModalStatusServer";
import { ModalInfo } from "../../../../../../../components/Modals/ModalInfo";
import { useModalData } from "../../../../../../../hooks/useModalData";

export const InputFileService = ({ idedificio, name, path, readOnly, label, onView, id, fileName = '' }: any) => {

    const [idAdjunto, setidAdjunto] = useState<any>(id);

    const [pathAdjunto, setpathAdjunto] = useState<any>(path);
    
    const MaxSizeMB = 10000000

    const [dataModal_InfoError, handleShowModal_InfoError, handleCloseModal_InfoError] = useModalData()

    const Lang = useLang()


    const [fetchAdjuntoPOST, fetchAdjuntoPOSTData] = DocumentoServices.POST()

    const [fetchAdjuntoDELETE, fetchAdjuntoDELETEData] = DocumentoServices.DELETE()


    const [errorServer, setErrorServer] = useState(false)

    const handleOnchange = (e: any) => {

        // onChange({
        //     target: {
        //         name,
        //         value: e.target.files?.[0]
        //     }
        // })

    }

    const [fileLoad, setfileLoad] = useState('');

    useEffect(() => {

        if (pathAdjunto) {
            setfileLoad(pathAdjunto)
        }

    }, [pathAdjunto]);

    // ------------ POST

    const handleOnChangeAll = (e: any) => {

        const file: any = e.target.files?.[0]

        if (e.target.files && file.size > MaxSizeMB) {

            handleShowModal_InfoError({})

            e.target.value = null;

            return
        }

        fetchAdjuntoPOST({
            body: {
                edifici_id: idedificio,
                nom: file?.name,
                tipus_document: name,
                file,
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAdjuntoPOSTData.status)) {

            setfileLoad(fetchAdjuntoPOSTData?.data?.data?.nom || 'File')
            setidAdjunto(fetchAdjuntoPOSTData?.data?.data?.id)
            setpathAdjunto(fetchAdjuntoPOSTData?.data?.data?.url_download)
        }

    }, [fetchAdjuntoPOSTData]);


    // -------------- DELETE

    const handleOnChangeDelete = (e: any) => {

        fetchAdjuntoDELETE({
            id: idAdjunto,
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAdjuntoDELETEData.status)) {

            setfileLoad('')
        }

    }, [fetchAdjuntoDELETEData]);

    // ------------- GET

    const handleDocumentGET = (url: any) => {

        openLinkByClick(url)
    }


    return (
        <>
            <Typography variant='body2' className='mb-1' component="span">
                {label}
            </Typography>
            <div className='d-flex align-items-center flex-wrap'>
                {
                    fileLoad &&
                    <>
                        <span className='InputFile__ContainerName flex-grow-1 me-2 py-2 cursor-pointer' onClick={() => handleDocumentGET(pathAdjunto)}>
                            <FontAwesomeIcon icon={faFile} className="me-2" /> {fileName || fileLoad?.split('/')?.pop()}
                        </span>
                        {
                            !readOnly &&
                            <Button
                                id='eliminar-file'
                                variant="outlined"
                                component="label"
                                size='small'
                                onClick={handleOnChangeDelete}
                                disabled={fetchAdjuntoDELETEData?.loading}
                            >
                                {fetchAdjuntoDELETEData?.loading && <CircularProgress size={18} className="me-2" style={{ color: 'inherit' }} />}

                                {Lang('ELIMINAR_ARCHIVO')}
                            </Button>
                        }
                    </>
                }

                {
                    !fileLoad &&
                    <>
                        <span className='InputFile__ContainerName flex-grow-1 me-2 py-2'>{Lang('SIN_ARCHIVOS_SELECCIONADOS')}</span>
                        <Button
                            variant="outlined"
                            component="label"
                            size='small'
                            disabled={fetchAdjuntoPOSTData?.loading}
                        >
                            {fetchAdjuntoPOSTData?.loading && <CircularProgress size={18} className="me-2" style={{ color: 'inherit' }} />}
                            {Lang('SELECCIONAR_ARCHIVO')}
                            <input
                                type="file"
                                hidden
                                // ref={referencia}
                                onChange={handleOnChangeAll}
                            />
                        </Button>
                    </>
                }
            </div>

            <ModalStatusServer fetchData={fetchAdjuntoPOSTData} onlyError />

            <ModalStatusServer fetchData={fetchAdjuntoDELETEData} onlyError />

            <ModalInfo
                data={dataModal_InfoError}
                handleClose={handleCloseModal_InfoError}
                message={Lang('SE_HA_EXCEDIDO_PESO_PERMITIDO')}
            />
        </>
    )
};
