import { useEffect, useState } from "react";
import { TabsAdvanced } from "../../../../../../components/TabsAdvanced/TabsAdvanced";
import { useLang } from "../../../../../../language";
import { Grid } from '@mui/material'
import { TableElaborarPlan } from "./TableElaborarPlan";
import { TableElaborarPlanResumen } from "./TableElaborarPlanResumen";
import { isFetchDataInitLoader, isStatusSuccess } from "../../../../../../helpers/helpers";
import { MaestrosServices } from "../../../../../../controllers/maestros/maestros.services";
import { MejoraFasesServices } from "../PlanActuacion/Fases/controllers/fases.services";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";

export const ElaborarPlan = ({ idedificio }: any) => {

    const Lang = useLang()

    const [fetchMejoraFases, fetchMejoraFasesData] = MejoraFasesServices.GET()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    useEffect(() => {

        fetchParamsPublicGET({
            body: [
                {
                    parametroPadre: "tipus_cost_milloras",
                },
                {
                    parametroPadre: "tipus_dificultat_milloras",
                },
            ]
        })

    }, []);

    useEffect(() => {

        if (idedificio) {

            fetchMejoraFases({
                params: {
                    edifici_id: idedificio,
                }
            })
        }

    }, [idedificio]);

    const [fases, setfases] = useState<any>([]);

    useEffect(() => {

        if (isStatusSuccess(fetchMejoraFasesData.status)) {

            const items = fetchMejoraFasesData.data?.items || []

            setfases([
                ...items,
                {
                    id: 'resumen',
                    name: Lang('RESUMEN'),
                },
            ])
        }

    }, [fetchMejoraFasesData]);

    return (
        <>
            <div className="mb-5">
                {
                    isFetchDataInitLoader(fetchMejoraFasesData) ?
                        <TableAdvancedLoader />
                        :

                        <TabsAdvanced>
                            <TabsAdvanced.Head
                                // centered={true}
                                values={fases}
                            />
                            <TabsAdvanced.Body>
                                {
                                    fases?.map((item: any, index: number) => {
                                        return (
                                            <section className="pt-3" key={index}>

                                                <Grid container spacing={3} rowSpacing={3}>

                                                    <Grid item xs={12}>

                                                        {
                                                            item.id === 'resumen' ?
                                                                <TableElaborarPlanResumen
                                                                    idedificio={idedificio}
                                                                />
                                                                :
                                                                <TableElaborarPlan
                                                                    idFase={item.id}
                                                                    idedificio={idedificio}
                                                                    fetchParamsPublicGETData={fetchParamsPublicGETData}
                                                                />
                                                        }


                                                    </Grid>

                                                </Grid>
                                            </section>
                                        )
                                    })
                                }

                            </TabsAdvanced.Body>

                        </TabsAdvanced>
                }
            </div>
        </>
    );
};


const TableAdvancedLoader = () => {
    return (
        <>
            <Grid container columnSpacing={1} rowSpacing={4}>
                <Grid item xs={12} md={1}>
                    <Placeholder className='px-4 py-1' height='2rem' />
                </Grid>
                <Grid item xs={12} md={1}>
                    <Placeholder className='px-4 py-1 delay-1' height='2rem' />
                </Grid>
                <Grid item xs={12} md={1}>
                    <Placeholder className='px-4 py-1 delay-2' height='2rem' />
                </Grid>
                <Grid item xs={12} md={1}>
                    <Placeholder className='px-4 py-1 delay-3' height='2rem' />
                </Grid>

                <Grid item xs={12}>
                    <Placeholder className='px-4 py-1' height='2rem' />
                </Grid>
                <Grid item xs={12}>
                    <Placeholder className='px-4 py-1 delay-1' height='10rem' />
                </Grid>
            </Grid>
        </>
    )
}
