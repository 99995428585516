import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../helpers/validations'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputTextarea } from "../../../../../../../components/Inputs/InputTextarea"
import { MantCorrectDeficienciaServices } from "./controllers/mantpreventivo.services"
import { DeficienciaFotos } from "./DeficienciaFotos/DeficienciaFotos"
import { MaestrosServices } from "../../../../../../../controllers/maestros/maestros.services"
import { TabsAdvanced } from "../../../../../../../components/TabsAdvanced/TabsAdvanced"

export const ModalMantCorrectivo = ({ data, handleClose, fetchParamsAgentesGETData, idedificio, fetchSuccess,
    formValuesSearch, fetchParamsDocumentosGETData, id }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchMantPrevGET, fetchMantPrevGETData] = MantCorrectDeficienciaServices.GET()

    const [fetchMantPrevPOST, fetchMantPrevPOSTData] = MantCorrectDeficienciaServices.POST()

    const [fetchMantPrevPATCH, fetchMantPrevPATCHData] = MantCorrectDeficienciaServices.PATCH()

    const [fetchMantPrevDELETE, fetchMantPrevDELETEData] = MantCorrectDeficienciaServices.DELETE()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        obligatorietat: [],
        manual_habitatge: [],
        qualificacio: '',
        observacions: '',
        descripcio: '',
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        descripcio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        qualificacio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        actuacio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevGETData.status)) {

            handleFormDocumento({
                ...fetchMantPrevGETData.data
            })
        }

    }, [fetchMantPrevGETData]);


    // ------------ POST

    const handleSubmitDocumentoPOST = () => {

        if (handleValidDocumento(formDocumento)) {

            fetchMantPrevPOST({
                body: {
                    ...formDocumento,
                    edifici_id: idedificio,
                    elemento_constructivo_id: id,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevPOSTData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevPOSTData]);


    // ------------ PATCH Agente

    const handleSubmitDocumentoPATCH = () => {

        if (handleValidDocumento(formDocumento)) {

            fetchMantPrevPATCH({
                id: data?.id + '/update',
                body: {
                    ...formDocumento,
                    edifici_id: idedificio,
                    elemento_constructivo_id: id,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchMantPrevDELETE({
            id: data?.id + '/destroy',
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevDELETEData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevDELETEData]);


    useEffect(() => {

        if (data.value) {

            fetchParamsPublicGET({
                body: [
                    {
                        parametroPadre: "calificacion_deficiencia",
                    },
                ]
            })

            if (data?.id) {

                fetchMantPrevGET({
                    id: data?.id + '/edit'
                })

            }

        } else {
            resetFormDocumento()
            resetvalidDocumento()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVA_ACTUACION') : Lang('EDITAR_ACTUACION')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    {
                        fetchMantPrevGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>

                                        <TabsAdvanced>
                                            <TabsAdvanced.Head
                                                // centered={true}
                                                values={[
                                                    {
                                                        id: 'actuacion',
                                                        name: Lang('ACTUACION'),
                                                    },
                                                    {
                                                        id: 'descripcion',
                                                        name: Lang('IMAGENES'),
                                                    },
                                                ]}
                                            />
                                            <TabsAdvanced.Body>
                                                <section className="pt-3">

                                                    <Grid container spacing={3} rowSpacing={3}>

                                                        <Grid item xs={12}>
                                                            <InputTextarea
                                                                label={Lang('ACTUACION') + ': *'}
                                                                name='actuacio'
                                                                value={formDocumento.actuacio}
                                                                onChange={handleInputDocumento}
                                                                fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                                                localValidation={validDocumento.actuacio}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography variant='body1' className='mb-1 fw-semibold' component="h5">
                                                                {Lang('DEFICIENCIA')}:
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} className='pt-2'>
                                                            <InputTextarea
                                                                label={Lang('DESCRIPCION_CORTA') + ': *'}
                                                                name='descripcio_curta'
                                                                value={formDocumento.descripcio_curta}
                                                                onChange={handleInputDocumento}
                                                                fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                                                localValidation={validDocumento.descripcio_curta}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <InputTextarea
                                                                label={Lang('DESCRIPCION') + ': *'}
                                                                name='descripcio'
                                                                value={formDocumento.descripcio}
                                                                onChange={handleInputDocumento}
                                                                fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                                                localValidation={validDocumento.descripcio}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <InputTextarea
                                                                label={Lang('OBSERVACIONES')}
                                                                name='observacions'
                                                                value={formDocumento.observacions}
                                                                onChange={handleInputDocumento}
                                                                fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                                                localValidation={validDocumento.observacions}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6} lg={4}>

                                                            <InputSelect
                                                                label={Lang('CALIFICACION')}
                                                                name='qualificacio'
                                                                value={formDocumento.qualificacio}
                                                                values={fetchParamsPublicGETData?.data?.['calificacion_deficiencia'] || []}
                                                                onChange={handleInputDocumento}
                                                                localValidation={validDocumento.qualificacio}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </section>
                                                <section className="pt-3">

                                                    <Grid container spacing={3} rowSpacing={3}>

                                                        <Grid item xs={12}>
                                                            <DeficienciaFotos
                                                                iddeficiencia={formDocumento.id}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </section>
                                            </TabsAdvanced.Body>

                                        </TabsAdvanced>

                                    </Grid>



                                </Grid>

                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className=" ms-auto"
                                handleSubmit={handleSubmitDocumentoPOST}
                                loadings={[fetchMantPrevPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className=""
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchMantPrevDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className=" ms-auto"
                                    handleSubmit={handleSubmitDocumentoPATCH}
                                    loadings={[fetchMantPrevPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchMantPrevPOSTData} />

            <ModalStatusServer fetchData={fetchMantPrevPATCHData} />

            <ModalStatusServer fetchData={fetchMantPrevDELETEData} />
        </>
    )
}