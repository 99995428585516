import React from "react";
import ModalAdvanced from "../../components/ModalAdvanced/ModalAdvanced";
import { Typography } from "@mui/material";
import { useLang } from "../../language";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const Faqs = ({ data, handleClose }: any) => {

    const Lang = useLang()

    const { language } = useSelector((state: RootState) => state.language)

    const handleShowFaqs = () => {

        let url = ''

        if (language === 'ES') {

            url = `${process.env.PUBLIC_URL}/files/ES/Preguntas frecuentes LED_cast_v10.pdf`

        } else if (language === 'CA') {

            url = `${process.env.PUBLIC_URL}/files/CA/Preguntes freqüents LED_cat_v10.pdf`
        }

        return url
    }

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl' className='bg-fafafa'>
                <ModalAdvanced.Head>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body className='mb-0'>

                    <iframe id='blob' className="border-0 min-vh-85 w-100" src={handleShowFaqs() + '#toolbar=0&navpanes=0&scrollbar=0'}></iframe>

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                </ModalAdvanced.Footer>

            </ModalAdvanced >
        </>
    )
};
