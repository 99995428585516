import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "../../../../../../../components/AccordionAdvanced/AccordionAdvanced.scss";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import './AccordionAdvancedPM.scss'
import { isEmpty } from "lodash";

export const AccordionAdvancedPM = ({ onlyOne, tree, setExpandedO, expandedO, setSelected, selected,
    handleShowModal_PM, isNivelAhorroEnergia }: any) => {

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpandedO: boolean) => {
        setExpandedO(isExpandedO ? panel : false);
    };

    const [expanded, setExpanded] = useState<any>([]);

    const handleToggle = (event: React.SyntheticEvent, nodeIds: any) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event: React.SyntheticEvent, nodeIds: any) => {
        setSelected(nodeIds);
    };

    const handleClickAgregarElemento = (e: any, id: number) => {

        e.preventDefault()
        handleShowModal_PM()
        // alert('hola')
    }

    const renderTree = (nodes: any) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} className={nodes.id?.split('_')?.length === 2 ? 'TreeView__Parent' : ''}
            label={
                <span className='Tree__label' onClick={(e) => isEmpty(nodes.children) ? isNivelAhorroEnergia(e) : null}>
                    <span>{nodes.name}</span>
                    {
                        nodes.help &&
                        <Tooltip title={nodes.help} placement="top">
                            <FontAwesomeIcon icon={faCircleInfo} className="ms-2" />
                        </Tooltip>
                    }
                </span>
            }
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node: any) => renderTree(node)) : null}
        </TreeItem>
    );

    return (
        <section className="AccordionAdvanced">
            {
                tree?.children?.map((item: any, index: number) => {

                    return (
                        <Accordion
                            component='article'
                            id={`${item.idOriginal}`}
                            key={`AccordionAdvanced-${index}`} expanded={onlyOne ? expandedO === index : undefined} onChange={handleChange(index)}
                        >
                            <AccordionSummary
                                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                                aria-controls="panel1bh-content"
                                // className="AccordionAdvancedSummary"
                                id={`${item.id}`}
                            >
                                <Typography className="fw-500">
                                    {item.texto}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    onlyOne && expandedO === index &&
                                    <TreeView
                                        aria-label="rich object"
                                        defaultCollapseIcon={<FontAwesomeIcon icon={faChevronUp} />}
                                        defaultExpanded={['root']}
                                        defaultExpandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                                        expanded={expanded}
                                        selected={selected}
                                        onNodeToggle={handleToggle}
                                        onNodeSelect={handleSelect}
                                        className="MetricasTreeView"
                                    >
                                        {
                                            item.children?.map((item2: any) => renderTree(item2))
                                        }
                                    </TreeView>
                                }
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </section>
    );
};
