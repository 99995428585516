import { useState, useEffect } from 'react'
import { ElementoConstructivoServices } from '../../controllers/elemconst.services';
import './LayoutDescConstNavTop.scss'
import { NavLink } from "react-router-dom";
import { ButtonAdvanced } from '../../../../../../components/ButtonAdvanced/ButtonAdvanced';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLang } from '../../../../../../language';
import { isStatusSuccess } from '../../../../../../helpers/helpers';
import { ModalStatusServer } from '../../../../../../components/Modals/ModalStatusServer';

export const LayoutDescConstNavTop = ({ id, idedificio, navLinks, className = '', text, defaultLink,
    elemento_completado = false, setDefaultLink }: any) => {

    const Lang = useLang()

    const [isCompleted, setisCompleted] = useState(elemento_completado);

    const [fetchElemConstPATCH, fetchElemConstPATCHData] = ElementoConstructivoServices.PATCH_BY_EDIFICIO()

    const handleFetchElemConstPATCH = (isCompleted: boolean) => {

        fetchElemConstPATCH({
            id: idedificio + '/edificio',
            body: {
                items: [
                    {
                        elemento_id: Number(id),
                        elemento_completado: isCompleted,
                    }
                ]
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchElemConstPATCHData.status)) {

            setisCompleted(!isCompleted)
        }

    }, [fetchElemConstPATCHData]);


    const handleActiveClassName = (isActive: boolean, item: any) => {

        if (isActive) {

            setDefaultLink(item.link)

            return 'LayoutDescConstNavTop__nav--active'
        }

        return ''
    }



    return (
        <>
            <nav className={`LayoutDescConstNavTop w-100 d-flex justify-content-between align-items-center ${className}`}>

                <h3 className="LayoutDescConstNavTop--title h3 mb-0 fw-bold mt-0">
                    <span>{text}</span>
                    {
                        (defaultLink === '' || defaultLink === 'sistemas') &&
                            <ButtonAdvanced
                                variant="contained" type="submit" className="ms-3" size='small' color={isCompleted ? 'success' : 'error'}
                                handleSubmit={() => handleFetchElemConstPATCH(!isCompleted)}
                                loadings={[fetchElemConstPATCHData.loading]}
                                value={isCompleted ? Lang('COMPLETADO') : Lang('PENDIENTE')} icon={<FontAwesomeIcon icon={faCheckDouble} className='me-2' />}
                            />

                    }

                </h3>

                <ul className='LayoutDescConstNavTop__nav list-unstyled d-flex mb-0 mt-auto pt-0 pe-0'>
                    {
                        navLinks?.map((item: any) => {
                            return (
                                <NavLink
                                    to={`./${item.link}`}
                                    // className={({ isActive }) => isActive ? "LayoutDescConstNavTop__nav--active" : ""}
                                    className={({ isActive }) => handleActiveClassName(isActive, item)}
                                    key={item.link}
                                >
                                    {item.title}
                                </NavLink>
                            )
                        })
                    }
                </ul>
            </nav>

            <ModalStatusServer fetchData={fetchElemConstPATCHData} />


        </>
    );
};
