import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { clearEmptyKeys, getValuesParams, isFetchDataInitLoader, isStatusSuccess, parseMoneda, setUrlByParams } from "../../../../../../helpers/helpers";
import { useForm } from "../../../../../../hooks/useForm";
import { useLang } from "../../../../../../language";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from "@mui/material";

import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './TableElaborarPlan.scss'
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { InputText } from "../../../../../../components/Inputs";
import { MedidaServices, MedidasServices } from "../PotencialMejoras/Medidas/controllers/medidas.services";
import { MejoraFaseServices } from "../PlanActuacion/Fases/controllers/fases.services";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";
import { cloneDeep, isEmpty } from "lodash";

export const TableElaborarPlan = ({ idFase, idedificio, fetchParamsPublicGETData }: any) => {

    const Lang = useLang()

    const [dataTemp, setdataTemp] = useState<any>([]);

    const [fetchMedidasGET, fetchMedidasGETData] = MedidasServices.GET()

    const [fetchFaseGET, fetchFaseGETData] = MejoraFaseServices.GET()

    const [valueDefault, setvalueDefault] = useState<any>('');

    const handleOnFocus = (e: any) => {
        setvalueDefault(e.target.value)
    }

    // Buscador

    const location = useLocation()

    const params: any = {};

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '100',
            order: 'orden asc',
            // padre_id: id,
            idfase: idFase,
            edifici_id: idedificio,

        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        idfase: params.idfase || allValuesSearch.idfase,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'idfase')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchMedidasGET({
                // id: idedificio,
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'idfase')

        fetchMedidasGET({
            // id: idedificio,
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])



    const [formFase, handleInputFase, handleFormFase, resetFormFase] = useForm({
        fase: '',
    })

    const handlefetchFaseGET = () => {

        fetchFaseGET({
            id: idFase
        })

    }

    useEffect(() => {

        handlefetchFaseGET()

    }, []);


    useEffect(() => {

        if (isStatusSuccess(fetchFaseGETData.status)) {

            handleFormFase({
                ...fetchFaseGETData.data
            })
        }

    }, [fetchFaseGETData]);

    useEffect(() => {

        if (isStatusSuccess(fetchMedidasGETData.status)) {

            setdataTemp(fetchMedidasGETData?.data?.items || [])
        }

    }, [fetchMedidasGETData]);

    const onSortEnd = (oldIndex: number, newIndex: number) => {

        let arrayEnd = cloneDeep(dataTemp)

        const newArrayEnd = arrayMoveImmutable(arrayEnd, oldIndex, newIndex)

        const ids = newArrayEnd?.map((item: any) => {
            return Number(item.id)
        })

        handleFetchOrdenarElementos(ids)

        setdataTemp(newArrayEnd)
    }

    const [fetchFasePATCH, fetchFasePATCHData] = MejoraFaseServices.PATCH_ONBLUR()

    const handleOnBlurAdvanced = (e: any) => {

        const nuevoValor = e.target.value

        if (valueDefault !== nuevoValor) {

            fetchFasePATCH({
                id: idFase,
                body: {
                    [e.target.name]: nuevoValor
                }
            })
        }

    }
    const [fetchMedidaFasePATCH, fetchMedidaFasePATCHData] = MedidaServices.PATCH_ONBLUR_COSTE()

    const handleOnBlurAdvancedFase = (id: string, e: any) => {

        const nuevoValor = e.target.value

        if (valueDefault !== nuevoValor) {

            fetchMedidaFasePATCH({
                id,
                body: {
                    [e.target.name]: nuevoValor
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMedidaFasePATCHData.status)) {

            handlefetchFaseGET()
        }

    }, [fetchMedidaFasePATCHData]);


    const [fetchMedidasFasesOrderPATCH, fetchMedidasFasesOrderPATCHData] = MedidasServices.PATCH_REORDENAR(idedificio)


    const handleFetchOrdenarElementos = (ids: any) => {
        fetchMedidasFasesOrderPATCH({
            body: {
                ids
            }
        })
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{Lang('REQUISITO')}</TableCell>
                            {/* <TableCell>Exigencia	</TableCell> */}
                            <TableCell >{Lang('ELEMENTOS')}	</TableCell>
                            <TableCell >{Lang('MEDIDA')}</TableCell>
                            <TableCell >{Lang('BENEFICIO')}</TableCell>
                            <TableCell >% {Lang('REDUCCION_CONSUMO')} EPnr	</TableCell>
                            <TableCell >{Lang('COSTE_MINIMO')}</TableCell>
                            <TableCell >{Lang('COSTE_MAXIMO')}</TableCell>
                            <TableCell >{Lang('COMPLEJIDAD')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        isEmpty(dataTemp) && fetchMedidasGETData.loading ?
                            <TableBody>
                                <TableAdvancedLoader />
                            </TableBody>
                            :

                            <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged" style={{ display: 'table-row-group' }}>

                                {dataTemp?.map((row: any, index: any) => (
                                    <SortableItem key={index}>

                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" className="position-relative">
                                                <SortableKnob>
                                                    <div className="TableTreeAdvanced__IconDrag">
                                                        <FontAwesomeIcon icon={faGripVertical} className='' />
                                                    </div>
                                                </SortableKnob>
                                            </TableCell>

                                            <TableCell >{row.nivel_principal_texto}</TableCell>
                                            <TableCell >{row.elemento_texto}</TableCell>
                                            <TableCell >{row.medida}</TableCell>
                                            <TableCell >{row.beneficio}</TableCell>
                                            <TableCell >{row.reduccion_cepnren}</TableCell>
                                            <TableCell>
                                                <div style={{ width: '100px' }}>
                                                    <InputText
                                                        defaultValue={row.coste_minimo}
                                                        name='coste_minimo'
                                                        type='number'
                                                        onBlur={(e: any) => handleOnBlurAdvancedFase(row.id, e)}
                                                        onFocus={handleOnFocus}
                                                        onChange={handleInputFase}
                                                        value={row.coste_minimo ? parseMoneda(row.coste_minimo) : ''}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell >
                                                <div style={{ width: '100px' }}>
                                                    <InputText
                                                        defaultValue={row.coste_maximo}
                                                        name='coste_maximo'
                                                        type='number'
                                                        onBlur={(e: any) => handleOnBlurAdvancedFase(row.id, e)}
                                                        onFocus={handleOnFocus}
                                                        onChange={handleInputFase}
                                                        value={row.coste_maximo ? parseMoneda(row.coste_maximo) : ''}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell >{row.complejidad_texto}</TableCell>
                                        </TableRow>

                                    </SortableItem>
                                ))}

                            </SortableList>
                    }


                </Table>
            </TableContainer>

            <div className="d-flex my-3 justify-content-between">
                <span className="position-relative">
                    {Lang('USE_ARRASTRE_ICONO')} <span style={{ color: '#8d8d8d' }} className="px-2"><FontAwesomeIcon icon={faGripVertical} className='' /></span> {Lang('PARA_CAMBIAR_ORDEN')}
                </span>
            </div>

            <Grid container spacing={3} rowSpacing={3} justifyContent='end'>

                <Grid item xs={12} md={6} lg={6}>


                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{Lang('TOTAL_PORCENTAJE_REDUCCION_CONSUMO_EPNR')}</TableCell>
                                    <TableCell>{Lang('TOTAL_COSTE_MINIMO')}</TableCell>
                                    <TableCell>{Lang('TOTAL_COSTE_MAXIMO')}</TableCell>
                                    <TableCell>{Lang('DURACION_MESES')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    isFetchDataInitLoader(fetchFaseGETData) ?
                                        <TableAdvancedLoader2 />
                                        :
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                <InputText
                                                    name='total_reduccion_consumo_epnr'
                                                    value={formFase.total_reduccion_consumo_epnr}
                                                    onChange={handleInputFase}
                                                    onBlur={handleOnBlurAdvanced}
                                                    onFocus={handleOnFocus}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <InputText
                                                    value={parseMoneda(formFase.total_coste_minimo)}
                                                    name='total_coste_minimo'
                                                    type='number'
                                                    onBlur={handleOnBlurAdvanced}
                                                    onChange={handleInputFase}
                                                    onFocus={handleOnFocus}
                                                    readOnly={true}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <InputText
                                                    value={parseMoneda(formFase.total_coste_maximo)}
                                                    name='total_coste_maximo'
                                                    type='number'
                                                    onChange={handleInputFase}
                                                    onBlur={handleOnBlurAdvanced}
                                                    onFocus={handleOnFocus}
                                                    readOnly={true}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <InputText
                                                    value={formFase.duracion}
                                                    name='duracion'
                                                    type='number'
                                                    onChange={handleInputFase}
                                                    onBlur={handleOnBlurAdvanced}
                                                    onFocus={handleOnFocus}
                                                />
                                            </TableCell>

                                        </TableRow>
                                }




                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        </>
    )
};

const TableAdvancedLoader = () => {
    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-1' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-2' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-3' height='1.1rem' />
                </TableCell>
            </TableRow>
        </>
    )
}



const TableAdvancedLoader2 = () => {
    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1' height='1.1rem' />
                </TableCell>
            </TableRow>
        </>
    )
}