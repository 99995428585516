const LINKS = {
    LINK_TEST_ENERGETICO: 'http://www.testenergetic.cat',
    LINK_MARKETCONS: 'http://www.marketcons.cat',
    LINK_CONSTRULEX: 'http://www.construlex.cat',
    LINK_CONSTRUDOC: 'http://www.construdoc.cat',
    LINK_TEST_ALUMINOSIS: 'http://www.testaluminos.cat',
    LINK_TEST_RADON: 'http://www.testrado.cat',
    LINK_CONTRATO_PRESTACION: `${process.env.PUBLIC_URL}/files/CA/MODEL CONTRACTE PRESTACIÓ DE SERVEIS_gestió manteniment_v1.docx`,
    LINK_ASSET_PERFILES: `${process.env.PUBLIC_URL}/files/CA/perfiles_cat.jpg`,

}

export default LINKS;