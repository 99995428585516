import { useLang } from "../../../../../../language";
import { Navigate, Route, Routes } from "react-router-dom";
import { LayoutPotencialMejoraNavTop } from "./LayoutPotencialMejoraNavTop";
import { PotencialMejoras } from "../PotencialMejoras/PotencialMejoras";
import { Container, Grid } from "@mui/material";
import { ElaborarPlan } from "../ElaborarPlan/ElaborarPlan";
import { ResumenDescarga } from "../ResumenDescarga/ResumenDescarga";
import { PlanActuacion } from "../PlanActuacion";

export const LayoutPotencialMejora = ({ id, text, textMain, idedificio }: any) => {

    const Lang = useLang()

    const navLinks = [
        {
            title: `1. ${Lang('POTENCIAL_MEJORAS')}`,
            link: 'potencial-mejoras'
        },
        {
            title: `2. ${Lang('ASIGNAR_FASES')}`,
            link: 'plan-actuacion'
        },
        {
            title: `3. ${Lang('ELABORAR_PLAN')}`,
            link: 'elaborar-plan'
        },
        {
            title: `4. ${Lang('RESUMEN_DESCARGA')}`,
            link: 'resumen-descarga'
        },
    ]

    return (
        <>
            <Container maxWidth={false} className='mb-auto'>

                <Grid container spacing={3}>

                    <Grid item xs={12} className='mt-0'>

                        <div className="LayoutDescConst__NavTop d-flex pt-0">

                            <LayoutPotencialMejoraNavTop
                                navLinks={navLinks}
                                text={text}
                                id={id}
                                idedificio={idedificio}
                            // elemento_completado={fetchMisElemsConstData?.data?.[0]?.elemento_completado}
                            />

                        </div>

                        <div className="LayoutDescConst__Container mt-3">
                            <Routes>

                                <Route
                                    path='potencial-mejoras/:idPM/*'
                                    element={
                                        <PotencialMejoras
                                        // id={id}
                                        // idedificio={idedificio}
                                        />
                                    }
                                />

                                <Route
                                    path='potencial-mejoras/'
                                    element={
                                        <PotencialMejoras
                                        // id={id}
                                        // idedificio={idedificio}
                                        />
                                    }
                                />

                                <Route
                                    path='plan-actuacion/*'
                                    element={
                                        <PlanActuacion
                                            // id={id}
                                            idedificio={idedificio}
                                        />
                                    }
                                />

                                <Route
                                    path='elaborar-plan/*'
                                    element={
                                        <ElaborarPlan
                                            idedificio={idedificio}
                                        />
                                    }
                                />

                                <Route
                                    path='resumen-descarga*'
                                    element={
                                        <ResumenDescarga
                                            idedificio={idedificio}
                                        />
                                    }
                                />

                                <Route
                                    path="*"
                                    element={<Navigate to='potencial-mejoras' replace />}
                                />

                            </Routes>
                        </div>
                    </Grid>

                </Grid>

            </Container>
        </>
    );
};
